import React from "react"

import SEO from "./seo"
import Menu from "./menu"
import "../styles/global.css"

export default ({ children }) => {
  return (
    <>
      <SEO title="Alec De Busschère" />
      <Menu />
      <main>{children}</main>
    </>
  )
}
