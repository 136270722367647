import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import ig from "../images/ig.png"

import {
  Link as ChakraLink,
  Popover,
  PopoverTrigger,
  PopoverContent,
  ModalOverlay,
} from "@chakra-ui/core"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiWork {
        edges {
          node {
            id
            menu_order
            title
            slug
            published
          }
        }
      }
      allStrapiMenu {
        edges {
          node {
            id
            title
            menu_order
            page_content
          }
        }
      }
      strapiGraph {
        slug
        is_rendered
      }
    }
  `)

  // console.log({ data })

  return (
    <>
      <nav>
        <ChakraLink
          //  @ts-ignore */}
          as={GatsbyLink}
          className="nav-link menu-container-title"
          to="/"
          style={{ textDecoration: "none" }}
        >
          ↻
        </ChakraLink>

        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <ModalOverlay
                className="modal-overlay"
                style={{ display: isOpen ? "block" : "none" }}
              />
              <PopoverTrigger>
                <ChakraLink className="nav-link menu-container-title">
                  Works
                </ChakraLink>
              </PopoverTrigger>
              <PopoverContent
                className="popover-content"
                style={{ width: "90vw" }}
                usePortal={true}
              >
                {data?.allStrapiWork?.edges
                  ?.filter(({ node: { published } }) => published)
                  ?.filter(({ node: { menu_order } }) => menu_order > 0)
                  ?.sort(
                    (
                      { node: { menu_order: a } },
                      { node: { menu_order: b } }
                    ) => b - a
                  )
                  ?.map(({ node }, i) => {
                    console.log({ node })
                    // console.log(node.slug)
                    // console.log(node.title)
                    return (
                      <div
                        style={{
                          paddingTop: i === 0 ? "33px" : "0px",
                        }}
                        key={`menu-work-link-${node.id}`}
                      >
                        <ChakraLink
                          className="nav-link"
                          //  @ts-ignore
                          as={GatsbyLink}
                          to={`/${node.slug}`}
                          // style={{}}
                        >
                          {node.title}
                        </ChakraLink>
                      </div>
                    )
                  })}
              </PopoverContent>
            </>
          )}
        </Popover>

        {data.allStrapiMenu.edges
          ?.sort(({ node: { menu_order: a } }, { node: { menu_order: b } }) => {
            // console.log({ a, b })
            return a - b
          })
          .map(({ node }, i) => {
            // console.log({ node }, "menu")
            return (
              <Popover key={`menu-popover-${node.id}`} usePortal={true}>
                {({ isOpen, onClose }) => (
                  <>
                    <ModalOverlay
                      className="modal-overlay"
                      style={{ display: isOpen ? "block" : "none" }}
                    />
                    <PopoverTrigger>
                      <ChakraLink className="nav-link menu-container-title">
                        {node.title}
                      </ChakraLink>
                    </PopoverTrigger>
                    <PopoverContent className="popover-content">
                      <div
                        dangerouslySetInnerHTML={{ __html: node.page_content }}
                      />
                      {node.title === "Contact" ? (
                        <>
                          <a
                            href="https://www.instagram.com/alecdebusscherestudio/"
                            target="_blank"
                          >
                            <img
                              style={{ width: "2rem", padding: "0.4rem 0" }}
                              src={ig}
                            />
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </PopoverContent>
                  </>
                )}
              </Popover>
            )
          })}
        {/* Digging */}
        {data?.strapiGraph?.is_rendered && (
          <ChakraLink
            className="nav-link menu-container-title"
            //  @ts-ignore
            as={GatsbyLink}
            to={`/${data?.strapiGraph?.slug}`}
            // style={{}}
          >
            {"Digging"}
          </ChakraLink>
        )}
      </nav>
    </>
  )
}
